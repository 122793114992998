/* ImageCatalog.css */
.image-catalog {
  display: flex;
  gap: 10px;
}

.catalog-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
}

.more-images {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background-color: #f0f0f0;
  cursor: pointer;
  font-size: 24px;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.modal-image {
  max-width: 90%;
  max-height: 90%;
}

.close {
  position: absolute;
  top: 10px;
  right: 25px;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.prev, .next {
  position: absolute;
  top: 50%;
  color: white;
  font-size: 40px;
  font-weight: bold;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}
