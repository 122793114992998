/* SearchResultsPage.css */

/* Main container for the entire page */
.search-results-page {
    font-family: 'Roboto', sans-serif;
    color: #333;
  }
  
  /* Full-width 400px banner with a background image */
  .search-results-banner {
    position: relative;
    width: 100%;
    height: 400px;
    background: url('./media/home.jpg') center/cover no-repeat;
    overflow: hidden;
  }
  
  /* Dark overlay for text contrast */
  .search-results-banner-overlay {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.4);
  }
  
  /* Centered banner text */
  .search-results-banner-text {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #fff;
    text-align: center;
  }
  
  .search-results-banner-text h1 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .search-results-banner-text p {
    font-size: 1.2rem;
    max-width: 600px;
    margin: 0 auto;
  }
  
  /* Container for the results sections */
  .search-results-container {
    width: 90%;
    max-width: 1200px;
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  /* Each type of results in a separate section */
  .search-section {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 12px rgba(0,0,0,0.06);
  }
  
  /* Title for each category of results */
  .search-section-title {
    font-size: 1.6rem;
    color: #01759a;
    text-transform: uppercase;
    margin-bottom: 1rem;
    letter-spacing: 1px;
  }
  
  /* Row of cards */
  .search-cards-row {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  /* No results found message */
  .search-no-results {
    text-align: center;
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 12px rgba(0,0,0,0.06);
  }
  .search-no-results h2 {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
    color: #01759a;
  }
  .search-no-results p {
    color: #555;
    font-size: 1rem;
  }
  
  /* Responsiveness */
  @media (max-width: 768px) {
    .search-results-banner-text h1 {
      font-size: 2rem;
    }
    .search-results-banner-text p {
      font-size: 1rem;
      margin: 0 1rem;
    }
  }
  @media (max-width: 480px) {
    .search-results-banner-text h1 {
      font-size: 1.6rem;
    }
    .search-results-banner-text p {
      font-size: 0.9rem;
    }
  }
  