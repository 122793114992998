/* Ensure the footer is always at the bottom of the page */
body, html {
  margin: 0;
  padding: 0;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.footer {
  background-color: #1a405f;
  color: #ffffff;
  padding: 20px;
  text-align: center;
  position: relative;
  bottom: 0;
  top: 100%;
  width: auto;
  margin-top: auto; /* Push footer to the bottom */
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-content p {
  margin: 10px 0;
  font-size: 1.1rem;
  font-weight: 500;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}

.footer-links a {
  color: #b2dfdb;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #80cbc4;
  text-decoration: underline;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .footer-content {
    padding: 20px;
  }

  .footer-links {
    flex-direction: column;
    gap: 10px;
  }
}
