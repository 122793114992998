/* Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;600&family=Roboto:wght@400;500;700&display=swap');

body {
  font-family: "Roboto", sans-serif;
  background: #f7f7f7;
  color: #333;
}

.main-page {
  width: 100%;
  min-height: 100vh;
  /* Remove any top margin, so there's no spacing between Nav and banner. */
  margin-top: 0;
  overflow-x: hidden;
}

/* ===================== BANNER ===================== */
.banner {
  position: relative;
  width: 100%;
  /* Full width on desktop. 
     We ensure no margin around it by default. */
  height: auto; 
  min-height: 400px;
  background: url("./media/home.jpg") center no-repeat;
  background-size: contain;
  /* If you'd prefer the entire banner on screen (no cropping),
     'contain' is correct. It may show empty space if the ratio 
     is different from your container. Alternatively, consider 'cover' 
     if you want to fill but risk cropping. */
}

/* ===================== SEARCH SECTION ===================== */
.search-section {
  width: 90%;
  max-width: 1100px;
  margin: 2rem auto 3rem auto;
  text-align: center;
}

.search-seo-text {
  margin-bottom: 1rem;
  color: #444;
  line-height: 1.6;
  font-size: 1rem;
  max-width: 800px;
  margin: 0 auto 1.5rem auto;
}

.search-heading {
  font-family: 'Oswald', sans-serif;
  font-size: 2rem;
  color: #01759a;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.search-fields {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  justify-content: center;
}

.search-input,
.search-select {
  flex: 1;
  min-width: 140px;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 0.6rem 1rem;
  font-size: 1rem;
  outline: none;
}

.search-input:focus,
.search-select:focus {
  border-color: #01759a;
}

.search-button {
  background: #f68529;
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 0.6rem 1.5rem;
  cursor: pointer;
  font-size: 1rem;
  transition: 0.3s;
}

.search-button:hover {
  background: #01759a;
}

/* ===================== TOP CARDS SECTION ===================== */
.top-cards-section {
  width: 90%;
  max-width: 1200px;
  margin: 2rem auto;
  text-align: center;
}

.section-title {
  font-family: 'Oswald', sans-serif;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #01759a;
  text-transform: uppercase;
}

.top-cards-container {
  display: flex;
  gap: 2rem;
  justify-content: center;
  margin-top: 2rem;
}

.pro-card {
  flex: 1;
  min-width: 240px; /* wider default for bigger card */
  background: #fff;
  border-radius: 8px;
  padding: 2rem 1rem;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}

.pro-card:hover {
  transform: translateY(-6px);
  box-shadow: 0 4px 15px rgba(0,0,0,0.15);
}

.pro-card-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #01759a;
}

.pro-card-title {
  font-family: 'Oswald', sans-serif;
  font-size: 1.6rem;
  color: #01759a;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.pro-card-desc {
  color: #555;
  font-size: 0.95rem;
  line-height: 1.4;
}

/* Stacking them on mobile */
@media (max-width: 768px) {
  .top-cards-container {
    flex-direction: column;
    gap: 1rem;
  }
}

/* ===================== SECTIONS ===================== */
.section {
  width: 90%;
  max-width: 1200px;
  margin: 3rem auto;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
  padding: 2rem 1rem;
  animation: fadeUp 1s ease forwards;
  position: relative;
}

.section-description {
  margin-bottom: 2rem;
  border-left: 6px solid #01759a;
  border-radius: 4px;
  padding: 1rem;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  background: #fafafa;
  color: #444;
  line-height: 1.6;
  font-size: 1rem;
}

.section-family-picks {
  background: #fafafa;
  overflow: hidden;
}

.section-family-picks .section-bg {
  position: absolute;
  inset: 0;
  opacity: 0.1;
  pointer-events: none;
  z-index: -1;
  background: repeating-radial-gradient(
    circle,
    #f68529 0%,
    #f68529 10%,
    #01759a 10%,
    #01759a 20%
  );
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 85%);
}

/* ========== SCROLLABLE ROW + ARROWS ========== */
.scrollable-row-container {
  position: relative;
  display: flex;
  align-items: center;
}

.arrow {
  font-size: 2rem;
  cursor: pointer;
  padding: 0.5rem;
  user-select: none;
  background: #ddd;
  border-radius: 50%;
  margin: 0 0.5rem;
  flex-shrink: 0;
  transition: background 0.3s;
}

.arrow:hover {
  background: #01759a;
  color: #fff;
}

.left-arrow {
  margin-right: 0.3rem;
}

.right-arrow {
  margin-left: 0.3rem;
}

.scrollable-row {
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  padding-bottom: 1rem;
  scrollbar-width: none;
  scroll-behavior: smooth;
  flex: 1;
}

.scrollable-row::-webkit-scrollbar {
  display: none;
}

/* Increase default card width in the row for more breathing room
   if your Card uses 'flex: 0 0 auto;' we can do something like:
   .scrollable-row > .card-class { min-width: 220px; } 
   but that depends on your <Card> styling. 
   For demonstration, you might do this: */
.scrollable-row > div {
  min-width: 220px; /* Each card container gets bigger */
}

/* Smaller arrows + more card width on mobile */
@media (max-width: 768px) {
  .arrow {
    font-size: 1.4rem;
    padding: 0.3rem;
  }
  .scrollable-row > div {
    min-width: 280px; /* bigger for mobile so the user sees one card fully */
  }
}

/* ========== FADEUP ANIMATION ========== */
@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fade-up {
  animation: fadeUp 1s ease forwards;
}

/* ===================== RESPONSIVE TWEAKS ===================== */
/* You can add more if needed. */
