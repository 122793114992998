/* AboutUs.css */

/* Overall page container */
.aboutus-page {
  font-family: 'Roboto', sans-serif;
  color: #333;
}

/* Banner - full width, 400px height, background from ../media/about-us.jpg */
.aboutus-banner {
  position: relative;
  width: 100%;
  height: 400px;
  background: url('../media/about-us.jpg') center/cover no-repeat;
  overflow: hidden;
}

/* Dark overlay for the banner */
.aboutus-banner-overlay {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.3);
}

/* Banner text container */
.aboutus-banner-text {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;
  text-align: center;
}

.aboutus-banner-text h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.aboutus-banner-text p {
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto;
}

/* Main container for all sections */
.aboutus-container {
  width: 90%;
  max-width: 1200px;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

/* Each "aboutus-section" block */
.aboutus-section {
  background: #fff;
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 2px 12px rgba(0,0,0,0.06);
}

.section-title {
  text-align: center;
  font-size: 1.8rem;
  color: #01759a;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  letter-spacing: 1px;
}

.section-content p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
}

/* Services grid */
.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
}

.service-card {
  background-color: #f9f9f9;
  border-radius: 10px;
  text-align: center;
  padding: 2rem;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 2px 10px rgba(0,0,0,0.06);
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0,0,0,0.1);
}

.service-icon {
  font-size: 3rem;
  color: #01759a;
  margin-bottom: 1rem;
}

.service-card h3 {
  font-size: 1.4rem;
  color: #01759a;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

/* Values list */
.values-list {
  list-style: none;
  padding: 0;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.values-list li {
  display: flex;
  align-items: flex-start;
  background: #f9f9f9;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.value-icon {
  font-size: 1.8rem;
  color: #01759a;
  margin-right: 10px;
  flex-shrink: 0;
}

.values-list li strong {
  color: #01759a;
  margin-right: 8px;
}

/* Contact card */
.contact-card {
  background: #f9f9f9;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.contact-card p {
  font-size: 1.1rem;
  line-height: 1.5;
  color: #555;
  margin: 0.8rem 0;
}

.contact-card a {
  color: #01759a;
  text-decoration: none;
  font-weight: 600;
}

.contact-card a:hover {
  text-decoration: underline;
}

/* Responsiveness */
@media (max-width: 768px) {
  .aboutus-banner-text h1 {
    font-size: 2rem;
  }
  .aboutus-banner-text p {
    font-size: 1rem;
    margin: 0 1rem;
  }
  .service-card {
    padding: 1.5rem;
  }
}

@media (max-width: 480px) {
  .aboutus-banner-text h1 {
    font-size: 1.6rem;
  }
  .aboutus-banner-text p {
    font-size: 0.9rem;
  }
  .service-icon {
    font-size: 2.5rem;
  }
  .service-card h3 {
    font-size: 1.2rem;
  }
}
