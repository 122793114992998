/* AllPackages.css */

.packages-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
  }
  
  /* Banner => ../media/packages.jpg */
  .packages-banner {
    width: 100%;
    height: 400px;
    background-image: url('../media/packages.jpg') !important;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .packages-content {
    display: flex;
    flex-direction: row;
    padding: 30px;
    gap: 20px;
    align-items: flex-start; /* fix card vs. filter height */
  }
  
  /* Filters side */
  .packages-filters {
    width: 250px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 12px rgba(0,0,0,0.08);
    padding: 20px;
    flex-shrink: 0;
  }
  
  .packages-filters h2 {
    font-size: 1.2rem;
    color: #01759a;
    margin-bottom: 15px;
  }
  
  .filter-group {
    margin-bottom: 20px;
  }
  
  /* Right side => SEO text + cards */
  .packages-main {
    flex: 1; 
    display: flex;
    flex-direction: column;
  }
  
  .seo-text {
    background: #fff;
    padding: 15px;
    margin-bottom: 15px;
    border-left: 4px solid #01759a;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  }
  
  .seo-text h3 {
    margin-bottom: 8px;
    color: #01759a;
    font-size: 1.2rem;
  }
  .seo-text p {
    color: #444;
    line-height: 1.5;
  }
  
  .packages-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding-bottom: 20px;
    align-items: flex-start;
  }
  
  .packages-cards .card {
    min-height: 280px;
    flex: 0 0 auto;
  }
  
  @media (max-width: 768px) {
    .packages-content {
      flex-direction: column;
    }
    .packages-filters {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  