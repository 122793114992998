.favorites-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Arial, sans-serif';
  color: #333;
}

.favorites-container h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
  color: #0056b3;
  font-weight: 700;
}

.favorites-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}
