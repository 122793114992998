/* ----------------------------------
   Global Variables & Base Resets
---------------------------------- */
:root {
  /* You can import these fonts in your index.html or use <link> in the head */
  --font-heading: 'Montserrat', sans-serif;
  --font-body: 'Open Sans', Arial, sans-serif;

  --base-font-size: 16px;
  --line-height: 1.7;

  --color-bg: #fdfdfd;           /* Page background */
  --color-container: #ffffff;    /* Container background */
  --color-text: #2c3e50;         /* Default text color */

  --color-accent: #017599;       /* Blue accent color */
  --color-accent-hover: #016287; /* Darker hover for accent */

  --color-label: #f58529;        /* Orange for labels & icon highlights */
  --color-check: #28a745;        /* Green for included items */
  --color-times: #dc3545;        /* Red for excluded items */

  --border-radius: 8px;
  --box-shadow: 0 2px 10px rgba(0,0,0,0.06);

  --spacing-xs: 0.5rem;
  --spacing-sm: 1rem;
  --spacing-md: 1.5rem;
  --spacing-lg: 2rem;
  --transition: 0.3s;

  /* Font sizes for headings, etc. */
  --h1-size: 1.75rem;
  --h2-size: 1.25rem;
  --h3-size: 1.15rem;
  --h4-size: 1rem;
}

/* Reset default margins/padding */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Base HTML settings */
html {
  font-size: var(--base-font-size);
  line-height: var(--line-height);
  background-color: var(--color-bg);
  color: var(--color-text);
  scroll-behavior: smooth;
}
body {
  font-family: var(--font-body);
}

/* Simple loading placeholder */
.loading {
  text-align: center;
  margin-top: var(--spacing-md);
  font-size: 1.2rem;
  color: var(--color-text);
}

/* ----------------------------------
   Page Container
---------------------------------- */
.page-container {
  max-width: 1200px;
  margin: var(--spacing-lg) auto;
  padding: var(--spacing-lg);
  background: var(--color-container);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

/* ----------------------------------
   Header Section
---------------------------------- */
.header-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-md);
}

.main-title {
  font-family: var(--font-heading);
  font-size: var(--h1-size);
  font-weight: 700;
  color: var(--color-accent);
  letter-spacing: 0.5px;
  margin: 0;
}

/* Favorite icon */
.fav-icon {
  font-size: 1.8rem;
  color: #a0a4a8; /* subtle by default */
  cursor: pointer;
  transition: color var(--transition);
}
.fav-icon:hover {
  color: var(--color-label); /* orange on hover */
}
.fav-icon.active {
  color: var(--color-label);
}

/* ----------------------------------
   Top Section (Image + Booking)
---------------------------------- */
.top-section {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-md);
}

.left-column {
  flex: 1.3;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.main-image {
  width: 100%;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.right-column {
  flex: 1;
  min-width: 280px;
  max-width: 400px;
}

/* Booking panel - keep white with accent headings */
.booking-panel {
  background: #fff;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: var(--spacing-md);
  border: 1px solid #dee2e6;
}

.booking-panel h2 {
  font-family: var(--font-heading);
  margin-bottom: var(--spacing-sm);
  font-size: var(--h2-size);
  font-weight: 600;
  color: var(--color-accent);
}

/* Force uniform field widths inside booking panel */
.booking-panel .MuiFormControl-root,
.booking-panel .MuiButtonBase-root {
  width: 100% !important;
}

/* MUI label text in orange for better visibility */
.booking-panel .MuiInputLabel-root,
.booking-panel .MuiFormLabel-root {
  color: var(--color-label) !important;
}

.price-label {
  margin-top: var(--spacing-sm);
  font-weight: 500;
}

/* MUI text fields: standard white background, black text */
.booking-panel .MuiInputBase-root {
  background: #fff;
  color: #000;
  border-radius: 4px;
}
.booking-panel .MuiInputBase-input {
  color: #000;
}

/* MUI contained button in accent color for strong CTA */
.booking-panel .MuiButton-contained {
  background-color: var(--color-accent) !important;
  color: #fff !important;
  margin-top: var(--spacing-xs);
  transition: background-color var(--transition) !important;
  font-weight: 600 !important;
}
.booking-panel .MuiButton-contained:hover {
  background-color: var(--color-accent-hover) !important;
}

/* ----------------------------------
   Info Section
---------------------------------- */
.info-section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.detail-box {
  background: #fafafa;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: var(--spacing-md);
  border: 1px solid #dee2e6;
}

/* Make the description stand out */
.detail-box .description {
  background: #fff;
  border-left: 4px solid var(--color-accent);
  padding: var(--spacing-sm);
  margin-bottom: var(--spacing-md);
  box-shadow: var(--box-shadow);
  font-size: 1rem;
  line-height: 1.75;
}

/* Field line: optionally wrap your "Price:", "Location:", etc. in .field-line with .field-label for color */
.field-line {
  margin-bottom: 0.6rem;
  line-height: 1.6;
}
.field-label {
  color: var(--color-label);
  font-weight: 600;
  margin-right: 0.3rem;
}

/* included / excluded area */
.included-excluded-area {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-md);
  margin-top: var(--spacing-sm);
}

/* "Included" / "Not Included" headings stand out more */
.included-excluded-area > div > p {
  margin-bottom: var(--spacing-xs);
  font-size: var(--h4-size);
  font-weight: 600;
  color: var(--color-label); /* orange text for more notice */
}

.included-excluded-area > div {
  flex: 1;
}

/* remove default bullets */
.included-excluded-area ul {
  list-style-type: none;
  padding-left: 0;
}
.included-excluded-area li {
  margin: 0.4rem 0;
  display: flex;
  align-items: center;
}

.check-icon {
  margin-right: 0.4rem;
  color: var(--color-check); /* green for included */
}
.times-icon {
  margin-right: 0.4rem;
  color: var(--color-times); /* red for excluded */
}

/* location & map area */
.location-box {
  margin-top: var(--spacing-sm);
}
.map-area {
  padding-left: var(--spacing-sm);
  border-left: 4px solid var(--color-accent);
  margin-top: 0.3rem;
}

/* ----------------------------------
   Collapsible Sections (Itinerary, FAQ)
---------------------------------- */
.collapsible-section {
  background: #fafafa;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  border: 1px solid #dee2e6;
  overflow: hidden;
  margin-bottom: var(--spacing-sm);
}

.collapsible-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-sm);
  cursor: pointer;
  transition: background var(--transition);
  background-color: #f1f1f1;
}
.collapsible-header:hover {
  background-color: #ebebeb;
}
.collapsible-header h3 {
  font-family: var(--font-heading);
  margin: 0;
  font-size: var(--h3-size);
  font-weight: 600;
  color: var(--text-color);
}

.collapsible-content {
  padding: var(--spacing-sm);
  max-height: 2000px;
  opacity: 1;
  transition: max-height var(--transition) ease, opacity var(--transition) ease;
}
.collapsible-content.collapsed {
  max-height: 0 !important;
  opacity: 0;
  overflow: hidden;
}

.itinerary-step {
  margin-bottom: var(--spacing-sm);
}
.itinerary-step h4 {
  margin-bottom: 0.3rem;
  font-size: var(--h4-size);
  font-family: var(--font-heading);
  color: var(--color-accent);
}

/* Enhanced FAQ item styling */
.faq-item {
  padding: var(--spacing-sm);
  border: 1px solid #dee2e6;
  border-radius: var(--border-radius);
  background: #fff;
  margin-bottom: var(--spacing-sm);
}
.faq-item p {
  margin-bottom: var(--spacing-xs);
  line-height: 1.5;
}
.faq-item p strong {
  color: var(--color-label); /* orange for Q / A label text */
}

/* ----------------------------------
   Reviews Section
---------------------------------- */
.reviews-section {
  background: #fafafa;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: var(--spacing-md);
  border: 1px solid #dee2e6;
  margin-top: var(--spacing-md); /* space above reviews */
}

.reviews-section h3 {
  font-family: var(--font-heading);
  margin-top: 0;
  margin-bottom: var(--spacing-sm);
  font-size: var(--h2-size);
  color: var(--color-accent);
  font-weight: 600;
}

.single-review {
  margin-bottom: var(--spacing-md);
}
.single-review hr {
  border: none;
  border-top: 1px solid #dcdcdc;
  margin: var(--spacing-sm) 0;
}
.review-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  color: var(--text-color);
}

.review-form {
  margin-top: var(--spacing-sm);
}

/* MUI text fields for review form remain standard white background */
.review-form .MuiTextField-root {
  background: #fff;
  border-radius: 4px;
  margin-bottom: var(--spacing-sm);
}

/* Review form button in accent color */
.review-form .MuiButton-contained {
  background-color: var(--color-accent) !important;
  color: #fff !important;
  transition: background-color var(--transition) !important;
}
.review-form .MuiButton-contained:hover {
  background-color: var(--color-accent-hover) !important;
}
