/* ContactUs.css */

/* Overall page container */
.contactus-page {
    font-family: 'Roboto', sans-serif;
    color: #333;
    width: 100%;
    margin: 0 auto;
  }
  
  /* Banner - full width, 400px tall, background from ../media/contact-us.jpg */
  .contactus-banner {
    position: relative;
    width: 100%;
    height: 400px;
    background: url('../media/contact-us.jpg') center/cover no-repeat;
    overflow: hidden;
  }
  
  /* Semi-transparent overlay for banner text contrast */
  .contactus-banner-overlay {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.4);
  }
  
  /* Centered banner text */
  .contactus-banner-text {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #fff;
    text-align: center;
  }
  
  .contactus-banner-text h1 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .contactus-banner-text p {
    font-size: 1.2rem;
    max-width: 600px;
    margin: 0 auto;
  }
  
  /* Main container for the rest of the page */
  .contactus-container {
    width: 90%;
    max-width: 1200px;
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  /* Contact methods (Phone, Email) displayed side by side */
  .contactus-methods {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
  }
  
  /* Each contact method card */
  .contactus-method-card {
    background: #fff;
    width: 280px;
    border-radius: 8px;
    box-shadow: 0 2px 12px rgba(0,0,0,0.08);
    text-align: center;
    padding: 1.5rem;
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
  }
  
  .contactus-method-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 16px rgba(0,0,0,0.12);
  }
  
  .contactus-method-icon {
    font-size: 3rem;
    color: #01759a;
    margin-bottom: 0.8rem;
  }
  
  .contactus-method-card h2 {
    font-size: 1.4rem;
    color: #01759a;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }
  
  .contactus-method-card p {
    font-size: 1rem;
    color: #555;
  }
  
  .contactus-method-card a {
    color: #01759a;
    text-decoration: none;
    font-weight: 600;
  }
  
  .contactus-method-card a:hover {
    text-decoration: underline;
  }
  
  /* JotForm iframe container */
  .contactus-form-container {
    width: 100%;
    height: 900px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 12px rgba(0,0,0,0.08);
  }
  
  /* The actual form iframe */
  .contactus-iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
  
  /* "3indak trip?" section with WhatsApp icon */
  .trip-section {
    background: linear-gradient(45deg, #01759a, #25D366);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex-direction: column;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 16px rgba(0,0,0,0.12);
    animation: pulse 2s infinite;
    text-align: center;
  }
  
  @keyframes pulse {
    0%, 100% {
      transform: scale(1);
      box-shadow: 0 4px 16px rgba(0,0,0,0.12);
    }
    50% {
      transform: scale(1.02);
      box-shadow: 0 8px 24px rgba(0,0,0,0.2);
    }
  }
  
  .trip-text {
    font-size: 1.6rem;
    font-weight: 700;
    color: #ffffff;
    text-shadow: 0 2px 4px rgba(0,0,0,0.3);
    margin-bottom: 1rem;
  }
  .trip-text span {
    color: #fff;
    text-decoration: underline;
    transition: color 0.3s;
  }
  .trip-text span:hover {
    color: #fffc;
  }
  
  .trip-whatsapp-icon {
    font-size: 3rem;
    color: #ffffff;
    background: #25D366;
    padding: 15px;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  }
  .trip-whatsapp-icon:hover {
    transform: scale(1.2);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.4);
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .contactus-banner-text h1 {
      font-size: 2rem;
    }
    .contactus-banner-text p {
      font-size: 1rem;
      margin: 0 1rem;
    }
    .contactus-method-card {
      width: 100%;
      max-width: 350px;
    }
    .trip-text {
      font-size: 1.4rem;
    }
  }
  
  @media (max-width: 480px) {
    .contactus-banner-text h1 {
      font-size: 1.6rem;
    }
    .contactus-banner-text p {
      font-size: 0.9rem;
    }
    .trip-text {
      font-size: 1.2rem;
    }
    .trip-whatsapp-icon {
      font-size: 2.4rem;
    }
  }
  