/* Base styles as provided */
.card {
  flex: 0 0 auto;
  width: 350px;
  margin: 10px;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  background-color: white;
  cursor: pointer;
  border: 1px solid #ddd;
  text-align: left;
}

.card-image-wrapper {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-bottom: 1px solid #ddd;
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s;
}

.card-content {
  padding: 10px 10px 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.card h2 {
  font-size: 20px;
  margin: 10px 0;
  color: #1781a1;
}

.card-details {
  display: flex;
  flex-direction: column;
}

.card-details p {
  margin: 5px 0;
  display: flex;
  gap: 5px;
  font-size: 14px;
  color: #555;
}

.card-to {
  color: #ff4500;
  margin-bottom: 10px;
}

.card-price {
  margin-top: 20px;
  font-size: 16px;
  color: #02b921;
  display: flex;
  font-weight: 600;
  align-items: baseline;
}

.card-icon {
  color: #e07f47;
}

.card-price span {
  font-size: 14px;
  margin-left: 5px;
  color: #555;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
}

.card:hover img {
  transform: scale(1.1);
}

/* Media Queries */

/* For devices with a max width of 1200px */
@media (max-width: 1200px) {
  .card {
    width: 300px;
  }
}

/* For devices with a max width of 992px */
@media (max-width: 992px) {
  .card {
    width: 250px;
  }
}

/* For devices with a max width of 768px */
@media (max-width: 768px) {
  .card {
    width: 200px;
  }
  .card h2 {
    font-size: 18px;
  }
  .card-content {
    padding: 10px;
  }
}

/* For devices with a max width of 576px */
@media (max-width: 576px) {
  .card {
    width: 100%;
    margin: 10px 0;
  }
  .card h2 {
    font-size: 16px;
  }
  .card-content {
    padding: 10px;
  }
}

.card-rating-row-inline {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}

.card-icon.star-icon {
  font-size: 18px;
  color: #ccc; /* Default for empty stars */
}

.card-icon.star-icon.filled-star {
  color: #FFD700; /* Gold color for full stars */
}

.card-icon.star-icon.half-star {
  background: linear-gradient(to right, #FFD700 50%, #ccc 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
}

.card-rating-value {
  font-size: 14px;
  color: #555;
  margin-left: 5px;
}

.card-reviews-count {
  color: #888;
  font-size: 12px;
  margin-left: 3px;
}
