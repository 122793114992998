/* PostList.css */

/* Overall container for the post list page */
.post-list-container {
  width: 90%;
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1rem;
}

/* Banner with background image */
.banner-blog {
  position: relative;
  width: auto;
  height: 400px;
  background: url('./media/blog.jpg') center/cover no-repeat !important;
  margin-bottom: 2rem;
}

/* Subtle dark overlay over the banner image for better text contrast */
.banner-overlay {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.3);
}

/* Banner text centered in the middle */
.banner-text {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 2rem;
  font-family: 'Oswald', sans-serif; /* If you want a bold, modern look */
  text-transform: uppercase;
  text-shadow: 0 2px 4px rgba(0,0,0,0.4);
}

/* Grid for posts */
.post-list-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
}

/* Card styling */
.post-card {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0,0,0,0.08);
  transition: transform 0.3s, box-shadow 0.3s;
  overflow: hidden; /* in case images overflow */
}

.post-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 16px rgba(0,0,0,0.12);
}

/* Card media (featured_image) */
.post-card-media {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

/* Card content */
.post-card-content {
  padding: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.post-card-title {
  color: #01759a;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
  transition: color 0.3s;
}
.post-card-title:hover {
  color: #f68529;
  text-decoration: underline;
}

/* Meta data row (date, author, category) */
.meta-data {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  margin-top: 0.8rem;
}

/* Each meta row: date, author, category */
.post-meta {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #555;
}

.post-meta-icon {
  margin-right: 0.4rem;
  color: #01759a; /* or #f68529, whichever you prefer for icons */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .banner-text {
    font-size: 1.6rem;
  }
  .post-list-container {
    margin: 1rem auto;
  }
}

@media (max-width: 480px) {
  .banner-text {
    font-size: 1.2rem;
    padding: 0 1rem;
    text-align: center;
  }
  .post-card-media {
    height: 160px;
  }
}
